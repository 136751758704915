import { useSearchParams } from 'react-router';

import { useI18n } from '@change-corgi/core/react/i18n';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { useShareHeadline } from '../../../../hooks/shareHeadline';

type UseMetaTextTags = ModelHookResult<{
	ogTitleContent: string;
	ogDescriptionContent: string;
	ogTwitterImageAlt: string;
}>;

export function useMetaTextOgTags(): UseMetaTextTags {
	const {
		data: {
			petition: { status, signatureState, ask },
		},
	} = usePetitionDetailsPageData();
	const { signatureCount } = signatureState;
	const { translate, translatePlural } = useI18n();
	const shareHeadline = useShareHeadline();
	const searchParams = useSearchParams()[0];

	let ogTitleContent = searchParams.get('ogTitle');

	if (!ogTitleContent) {
		if (status === 'VICTORY') {
			ogTitleContent = translatePlural('fe.facebook.share.victory', signatureCount.displayed, {
				signature_count: signatureCount.displayedLocalized,
			});
		} else {
			ogTitleContent = shareHeadline?.title || translate('fe.facebook.share.sign_petition');
		}
	}

	let ogDescriptionContent = searchParams.get('ogDescription');
	if (!ogDescriptionContent) {
		ogDescriptionContent = status === 'VICTORY' ? ask : shareHeadline?.description || ask;
	}

	return {
		data: {
			ogTitleContent,
			ogDescriptionContent,
			ogTwitterImageAlt: searchParams.get('ogTwitterImageAlt') || ogTitleContent,
		},
	};
}
