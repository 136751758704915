import { useSearchParams } from 'react-router';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { useMitaPhoto } from '../../../../hooks/mita';

export const DEFAULT_PHOTO = { url: 'https://static.change.org/images/default-petition.gif', width: 1120, height: 630 };

type UseMetaImageTags = ModelHookResult<{
	ogImage: string;
	ogImageWidth: string;
	ogImageHeight: string;
	ogTwitterImage: string;
}>;

export function useMetaImageOgTags(): UseMetaImageTags {
	const {
		data: {
			petition: { photo },
		},
	} = usePetitionDetailsPageData();
	const { resolveUrl } = useUtilityContext();
	const mitaPhoto = useMitaPhoto();
	const searchParams = useSearchParams()[0];

	const metaPhoto = mitaPhoto || photo?.petitionLarge || DEFAULT_PHOTO;
	const imageUrl = searchParams.get('ogImage') || resolveUrl(metaPhoto.url);

	return {
		data: {
			ogImage: imageUrl,
			ogImageWidth: searchParams.get('ogImageWidth') || `${metaPhoto.width}`,
			ogImageHeight: searchParams.get('ogImageHeight') || `${metaPhoto.height}`,
			ogTwitterImage: searchParams.get('ogTwitterImage') || imageUrl,
		},
	};
}
