import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useMetaImageOgTags } from './hooks/useMetaImageOgTags';
import { useMetaTextOgTags } from './hooks/useMetaTextOgTags';

export function MetaOGContainer(): JSX.Element | null {
	const {
		data: { ogDescriptionContent, ogTitleContent, ogTwitterImageAlt },
	} = useMetaTextOgTags();
	const {
		data: { ogImage, ogImageHeight, ogImageWidth, ogTwitterImage },
	} = useMetaImageOgTags();

	return (
		<Helmet>
			<meta property="og:title" content={ogTitleContent} />
			<meta property="og:description" content={ogDescriptionContent} />
			<meta property="og:image" content={ogImage} />
			<meta property="og:image:secure_url" content={ogImage} />
			{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
			<meta property="og:image:width" content={ogImageWidth} />
			{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
			<meta property="og:image:height" content={ogImageHeight} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="twitter:image" content={ogTwitterImage} />
			<meta property="twitter:image:alt" content={ogTwitterImageAlt} />
		</Helmet>
	);
}
